import {IntegrationTypeEnum} from './enums/integration.type.enum';
import EnumUtils from '../utils/EnumUtils';
import EmployeeNotSyncModel from './employee.not.sync.model';

export default class IntegrationModel {
  id: string;
  type: IntegrationTypeEnum;
  origin: IntegrationTypeEnum = IntegrationTypeEnum.SOLIDES;
  headers = {};
  params: any[] = [];
  lastSync: Date;
  createdAt: Date;
  available: boolean;
  vacation: boolean = false;
  manager: boolean = false;
  employeesNotSynchronized: EmployeeNotSyncModel[] = [];

  builder() {
    return {
      setId(id) {
        this.id = id;
        return this;
      },
      setType(type) {
        this.type = EnumUtils.getEnumByValue(IntegrationTypeEnum, type);
        return this;
      },
      setOrigin(origin) {
        this.origin = EnumUtils.getEnumByValue(IntegrationTypeEnum, origin);
        return this;
      },
      setHeaders(headers) {
        this.headers = headers;
        return this;
      },
      setLastSync(lastSync) {
        this.lastSync = lastSync;
        return this;
      },
      setCreatedAt(createdAt) {
        this.createdAt = createdAt;
        return this;
      },
      setAvailable(available) {
        this.available = available;
        return this;
      },
      setVacation(vacation) {
        this.vacation = vacation;
        return this;
      },
      setManager(manager) {
        this.manager = manager;
        return this;
      },
      setEmployeesNotSynchronized(employees) {
        this.employeesNotSynchronized = employees;
        return this;
      }
    };
  }
}
