import { Component } from '@angular/core';
import { IntegrationService } from './services/integration.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private service: IntegrationService) {
    const urlParams = new URLSearchParams(window.location.search);

    this.service.employer = urlParams.get('employer');
    this.service.employerId = urlParams.get('employer-id')
  }
}
