import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VacationService {

  constructor(private http: HttpClient) { }

  verifyVacation(token: string) {
    return this.http.head(`${environment.VACATION_BASE_PATH}/management`, {
      headers: { 'TNG-WEB-TOKEN': token },
      observe: 'response'
    }).toPromise()
  }
}
