<mat-accordion multi>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>Para gerar o token de acesso siga os seguintes passos:</mat-expansion-panel-header>
    <div class="markdown">
      <ol>
        <li>Acesse
          <a rel="noopener noreferrer" target="_blank" href="https://system.solides.com/pt-BR/usuarios/sign_in">
            https://system.solides.com/pt-BR/usuarios/sign_in
          </a>e logue com seu usuário.
        </li>
        <li>Acesse o <b>Configurações | Configurações</b></li>
        <li>Na seção <b>Ativar API de Integração</b>, clique em <b>Ativar API</b>. Isso gerará um token único que deverá ser utilizado em todas as requisições.<br><i>**Caso já tenha feito a ativação, siga o passo 4</i>.</li>
        <li>Copie o token e cole no campo acima <b>Token de acesso</b>.</li>
      </ol>
    </div>
  </mat-expansion-panel>
</mat-accordion>
