<mat-accordion multi>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>Para gerar o token de acesso siga os seguintes passos:</mat-expansion-panel-header>
    <div class="markdown">
      <ol>
        <li>Acesse
          <a rel="noopener noreferrer" target="_blank" href="https://login.convenia.com.br">
            https://login.convenia.com.br
          </a>e logue com seu usuário.
        </li>
        <li>Acesse o <b>Menu do usuário no canto superior direito | Configurações</b></li>
        <li>No menu lateral selecione <b>API</b>.<br></li>
        <li>Na aba de Tokens clique em <b>Adicionar novo Token</b>.</li>
        <li>Defina um <b>nome</b> para o Token e finalize o cadastro.</li>
        <li>Na aba de <b>Tokens</b> copie o Token gerado para a integração com o Tangerino.</li>
      </ol>
    </div>
  </mat-expansion-panel>
</mat-accordion>
