import {Component, Inject, OnInit} from '@angular/core';

import {FormControl, FormGroup, Validators} from '@angular/forms';
import {getDescription, IntegrationTypeEnum} from 'src/app/models/enums/integration.type.enum';
import CookieUtils from 'src/app/utils/CookieUtils'
import EnumUtils from 'src/app/utils/EnumUtils';
import {IntegrationService} from 'src/app/services/integration.service';
import { VacationService } from './../../../services/vacation.service'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import IntegrationModel from 'src/app/models/integration.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {
  integrationModel: IntegrationModel;

  firstFormGroup = new FormGroup({
    application: new FormControl('', Validators.required),
    acceptConditions: new FormControl('', Validators.requiredTrue)
  });

  secondFormGroup: FormGroup = new FormGroup({
    token: new FormControl('', [Validators.required, Validators.minLength(10)])
  });

  loading = false;
  bringVacation = false;
  synchronized;
  isVacationAvailable = false
  headersAlreadyExists = false;

  constructor(
    private service: IntegrationService,
    private vacationService: VacationService,
    private dialogRef: MatDialogRef<CreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entity: IntegrationModel }
  ) {
    if (!data.entity) {
      this.integrationModel = new IntegrationModel();
    } else {
      this.integrationModel = _.assign({}, data.entity);
      this.firstFormGroup.get('application').setValue(data.entity.type);
      this.secondFormGroup.get('token').setValue(_.replace(data.entity.headers[`${this.getHeaderProperty()}`], 'Token token=', ''));
    }
  }

  async ngOnInit() {
    const vacationToken = CookieUtils.getCookieValue('_user_session_jwt_token')
    try{
      const response = await this.vacationService.verifyVacation(vacationToken)
      if(response.status === 200) this.isVacationAvailable = true
    }catch (error){
      console.error("error", error)
    }
  }

  async next() {
    if (this.integrationModel.type === 'CONVENIA') {
      this.validateData();
      return;
    }
  }

  getTokenPrefix(): string {
    return this.integrationModel.type === 'SOLIDES' ? 'Token token=' : '';
  }

  getHeaderProperty(): string {
    return this.integrationModel.type === 'SOLIDES' ? 'authorization' : 'token';
  }

  async validateData() {
    this.loading = true;

    this.integrationModel.headers = {
      [`${this.getHeaderProperty()}`]: `${this.getTokenPrefix()}${this.secondFormGroup.get('token').value}`
    };

    await this.service.validate(this.integrationModel).then((response: any) => {
      this.loading = false;
      this.synchronized = response.success;
    }).catch(error => {
      this.loading = false;
    });
  }

  async validateHeaders() {
    this.loading = true;

    this.integrationModel.headers = {
      [`${this.getHeaderProperty()}`]: `${this.getTokenPrefix()}${this.secondFormGroup.get('token').value}`
    };

    await this.service.validateHeaders(this.integrationModel)
      .then((response: any) => {
        if (!response.success) {
          this.headersAlreadyExists = false;
          return;
        }

        if (response.body && response.body._id != this.integrationModel.id) {
          this.headersAlreadyExists = response.success;
          this.service.showFeedBack('Este token de integração já está sendo utilizado por outro empregador.');
        }
      })
      .catch(error => {
        this.headersAlreadyExists = false;
      });
  }

  async save() {
    this.integrationModel.headers = {
      [`${this.getHeaderProperty()}`]: `${this.getTokenPrefix()}${this.secondFormGroup.get('token').value}`
    };

    if (JSON.stringify(this.integrationModel) === JSON.stringify(this.data.entity)) {
      this.service.showFeedBack('Integração salva com sucesso!');

      this.dialogRef.close();
      return;
    }

    if (this.integrationModel.id == null) {
      this.integrationModel.available = true;
    }

    this.service.save(this.integrationModel).then((response: any) => {
      if (response && response.success) {
        this.integrationModel.id = response.body.integration._id;
        this.integrationModel.createdAt = response.body.integration.createdAt;
        this.integrationModel.lastSync = response.body.integration.lastSync;
        this.integrationModel.vacation = response.body.module.vacation
        this.integrationModel.manager = response.body.module.manager

        _.remove(this.service.integrations, (integration) => integration.id === this.integrationModel.id);

        this.service.integrations.push(this.integrationModel);
        this.service.updateDataSource();

        this.service.showFeedBack('Integração salva com sucesso!');
        this.dialogRef.close();

        this.service.sync(this.integrationModel)
      }
    }).catch(response => {
      this.service.showFeedBack(response.error.body);
    });
  }

  setApplication(application) {
    this.integrationModel.type = EnumUtils.getEnumByValue(IntegrationTypeEnum, application);
    if (this.integrationModel.type === IntegrationTypeEnum.CONVENIA) {
      this.integrationModel.origin = IntegrationTypeEnum.CONVENIA;
    }
  }

  close() {
    this.dialogRef.close();
  }

  changeFlowDirection() {
    this.integrationModel.origin = this.integrationModel.origin === IntegrationTypeEnum.TANGERINO ?
      this.integrationModel.type : IntegrationTypeEnum.TANGERINO;
  }

  getFlowImage() {
    const prefix = this.integrationModel.origin === IntegrationTypeEnum.TANGERINO ? 'right' : 'left';
    return `../../../../assets/images/swap-horiz-${prefix}.svg`;
  }

  getApplicationTypeDescription() {
    return getDescription(this.integrationModel.type);
  }

  getApplicationOriginToDisplay() {
    return this.integrationModel.origin === IntegrationTypeEnum.TANGERINO ?
      getDescription(IntegrationTypeEnum.TANGERINO) : getDescription(this.integrationModel.type);
  }

  getApplicationDestineToDisplay() {
    return this.integrationModel.origin === IntegrationTypeEnum.TANGERINO ?
      getDescription(this.integrationModel.type) : getDescription(IntegrationTypeEnum.TANGERINO);
  }
}
