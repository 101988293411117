import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import IntegrationModel from '../../../models/integration.model';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  displayedColumns: string[] = ['name', 'inconsistency'];
  dataSource = [];

  constructor(
    private dialogRef: MatDialogRef<EmployeesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entity: IntegrationModel }
  ) {
    this.dataSource = data.entity.employeesNotSynchronized;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

}
