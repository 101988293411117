import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructions-solides',
  templateUrl: './instructions-solides.component.html',
  styleUrls: ['./instructions-solides.component.scss']
})
export class InstructionsSolidesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
