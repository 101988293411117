import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ErrorsComponent} from "./components/errors.component";


const routes: Routes = [
  { path: 'details', component: ErrorsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorsRoutingModule { }
