import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeService } from 'src/app/services/employee.service';
import DateUtils from 'src/app/utils/DateUtils';
import { MatTableDataSource } from '@angular/material/table';
import IntegrationModel from 'src/app/models/integration.model';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {
  displayedColumns: string[] = ['status', 'name', 'lastSync', 'reasonNotToSync'];
  dataSource = new MatTableDataSource([]);

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private dialogRef: MatDialogRef<HistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entity: IntegrationModel },
    service: EmployeeService
  ) {
    service.findAll(data.entity.id).then((response: Response) => {
      let body: any = response.body
      if (body && body.data) {
        let employees = []
        body.data.forEach((employee) => {
          employees.push({
            status: this.getStatus(employee),
            name: employee.name,
            lastSync: employee.lastSync,
            reasonNotToSync: employee.reasonNotToSync
          })
        })

        this.dataSource.data = employees
      }
    })
  }

  getStatus(employee) {
    if (employee.synchronized) {
      if (employee.reasonNotToSync) {
        return {
          value: 'PENDING',
          description: 'Pendente',
          style: 'color: orange',
          icon: 'assignment_late'
        };
      } else {
        return {
          value: 'SYNCHRONIZED',
          description: 'Sincronizado',
          style: 'color: #4caf50',
          icon: 'assignment_turned_in'
        };
      }
    }

    if (employee.reasonNotToSync) {
      if (employee.lastSync) {
        return {
          value: 'PENDING',
          description: 'Pendente',
          style: 'color: orange',
          icon: 'assignment_late'
        };
      }

      if (`${employee.reasonNotToSync}`.includes('[TANGERINO] indisponível')) {
        return {
          value: 'WAITING',
          description: 'Aguardando',
          style: 'color: blue',
          icon: 'pending_actions'
        };
      }

      return {
        value: 'ERROR',
        description: 'Não Sincronizado',
        style: 'color: red',
        icon: 'close'
      };
    }
    return {
      value: 'WAITING',
      description: 'Aguardando',
      style: 'color: blue',
      icon: 'pending_actions'
    };
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }

  formatDate(date: Date) {
    return DateUtils.formatDate(date)
  }

  close() {
    this.dialogRef.close();
  }
  announceSortChange(sort: Sort) {
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'status':
          return this.compare(a.status.value, b.status.value, isAsc);
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'lastSync':
          return this.compare(a.lastSync, b.lastSync, isAsc);
        case 'reasonNotToSync':
          return this.compare(a.reasonNotToSync, b.reasonNotToSync, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
