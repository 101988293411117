<button class="close" mat-button (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<mat-horizontal-stepper linear #stepper>
  <mat-step [stepControl]="firstFormGroup">
    <ng-template matStepLabel>Sistema</ng-template>
    <form [formGroup]="firstFormGroup">
      <div class="stteper-content center">
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="application">
          <mat-button-toggle value="SOLIDES" (click)="setApplication(group.value)" class="solides">
            <div></div>
          </mat-button-toggle>
          <mat-button-toggle value="CONVENIA" (click)="setApplication(group.value)" class="convenia">
            <div></div>
          </mat-button-toggle>
          <!--          <mat-button-toggle value="MASTERMAQ" (click)="setApplication(group.value)" class="mastermaq">-->
          <!--            <div></div>-->
          <!--          </mat-button-toggle>-->
        </mat-button-toggle-group>
      </div>
      <div class="controls center">
        <mat-checkbox formControlName="acceptConditions" color="primary">
          <mark>Todos os colaboradores estão cadastrados com CPF.</mark>
        </mat-checkbox>
        <button mat-raised-button color="primary" [disabled]="!this.firstFormGroup.valid" matStepperNext>
          Próximo
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup">
    <ng-template matStepLabel>Dados de Acesso</ng-template>
    <form [formGroup]="secondFormGroup">
      <div class="stteper-content">
        <mat-form-field class="input-full-width">
          <mat-label>Token de acesso</mat-label>
          <input matInput formControlName="token" (focusout)="validateHeaders()" required>
        </mat-form-field>
        <app-instructions-solides *ngIf="integrationModel.type==='SOLIDES'"></app-instructions-solides>
        <app-instructions-convenia *ngIf="integrationModel.type==='CONVENIA'"></app-instructions-convenia>
      </div>
      <div class="row controls justify-content-center">
        <button mat-stroked-button color="primary" matStepperPrevious>Voltar</button>
        <button mat-raised-button color="primary" matStepperNext [disabled]="headersAlreadyExists"
                (click)="validateData()">Próximo
        </button>
      </div>
    </form>
  </mat-step>
  <mat-step *ngIf="integrationModel.type === 'SOLIDES'">
    <ng-template matStepLabel>Fluxo</ng-template>
    <div class="stteper-content center">
      <div>
        Clique nas setas abaixo para escolher o sentido do fluxo de integração
      </div>
      <div class="flow-container">
        <img src="https://tangerino-resources.s3.amazonaws.com/logos/tangerino-by-solides/svg/logo-marca-by-solides-v5.svg" class="col-5" alt="Tangerino">
        <button mat-button class="flow-button col-2" (click)="changeFlowDirection()">
          <img [src]="getFlowImage()" alt="">
        </button>
        <img src="../../../../assets/images/solides.png" class="col-5" alt="Sólides">
      </div>
      <mat-card class="tip-card">
        A opção escolhida realiza a integração no sentido <b>{{ getApplicationOriginToDisplay() }}</b>
        para <b>{{ getApplicationDestineToDisplay() }}</b>.
      </mat-card>
    </div>
    <div class="row controls justify-content-center">
      <button mat-stroked-button color="primary" matStepperPrevious>Voltar</button>
      <button mat-raised-button color="primary" matStepperNext (click)="validateData()">Próximo</button>
    </div>
  </mat-step>

  <mat-step *ngIf="integrationModel.origin === 'SOLIDES'">
    <ng-template matStepLabel>Configuração</ng-template>
    <div class="stteper-content" style="position: relative;">
      <h3 style="text-align: center;">
        Selecione as opções de configuração abaixo
      </h3>
      <div style="margin-left: 25px">
        <mat-checkbox color="primary" [checked]="true" [disabled]="true">
          <mark>Colaboradores</mark>
        </mat-checkbox>
         <mat-checkbox color="primary" [(ngModel)]="integrationModel.vacation" *ngIf="isVacationAvailable">
          Férias
         </mat-checkbox>
         <mat-checkbox color="primary" [(ngModel)]="integrationModel.manager">
          Gestores
         </mat-checkbox>
      </div>
    </div>
    <div class="row controls justify-content-center">
      <button mat-stroked-button color="primary" matStepperPrevious>Voltar</button>
      <button mat-raised-button color="primary" matStepperNext (click)="validateData()">Próximo</button>
    </div>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Sincronização</ng-template>
    <div class="stteper-content">
      <div class="loading" *ngIf="this.loading">
        <img src="../../../../assets/images/loading.svg" alt="Loading">
      </div>
      <div class="loading" *ngIf="!synchronized && !this.loading">
        <mat-icon [inline]="true" class="basic">sync_problem</mat-icon>
        <p class="feedback">Não foi possível conectar ao servidor da {{ getApplicationTypeDescription() }}. Confira seus
          dados de acesso e
          tente novamente.</p>
      </div>
      <div class="loading" *ngIf="!this.loading && synchronized">
        <mat-icon [inline]="true" class="success">sync</mat-icon>
        <p>Dados validados com sucesso!</p>
      </div>
    </div>
    <div class="row controls justify-content-center">
      <button mat-stroked-button color="primary" matStepperPrevious>Voltar</button>
      <button mat-raised-button color="primary" [disabled]="!synchronized" (click)="save()">Salvar</button>
    </div>
  </mat-step>
</mat-horizontal-stepper>
