<div>
  <h2 class="tangerino-title">Integrações</h2>
  <mat-tab-group>
    <mat-tab label="Token Tangerino">
      <div class="card-msg" id="Token">
        <p>
          Com o Token de integração é possível conectar a sua aplicação com o Tangerino. Para isso, o token de
          integração deve ser informado no <b>'Headers'</b> das suas requisições.
        </p>

        <p>
          Segue abaixo o seu token de integração. Para realizar suas requisições no Postman, informe no Headers os
          seguintes valores:
        </p>

        <table class="table col-10 mat-elevation-z6">
          <thead class="thead-light">
            <th>Key</th>
            <th>Value</th>
          </thead>
          <tbody>
            <tr>
              <td>Authorization</td>
              <td>{{ token }}</td>
            </tr>
          </tbody>
        </table>

        <blockquote>
          <p>
            Para mais informações, acesse a nossa documentação <a target="_blank"
              href="https://docs.tangerino.com.br/">Tangerino
              Integrations</a> e conheça mais sobre os serviços que estamos construindo.
          </p>
          <p>Caso tenha alguma dúvida, entre em contato.</p>
          <span>
            <a target="_blank" href="mailto:contato@tangerino.com.br?subject=Boa%20Tarde" class="link"
              title="Clique aqui para ir para o nosso e-mail!">
              <i class="fa fas fa-envelope-square fa-2x"></i>
              <strong>E-mail: </strong>contato@tangerino.com.br
            </a></span>
        </blockquote>
      </div>
    </mat-tab>


    <mat-tab label="Sistemas Parceiros">
      <div class="table-container">
        <mat-card class="tip">
          <strong><i class="fas fa-exclamation-triangle"></i>Caso você já use sistemas parceiros e possua colaboradores
            cadastrados no Tangerino:</strong>
          <ol>
            <li>Verifique se o CPF do colaborador consta registrado em ambos os sistemas para a correta vinculação dos
              dados.
            </li>

            <li>Caso o dado não exista em algum dos sistemas efetue a atualização dos dados antes de prosseguir com a
              integração.
            </li>
          </ol>
        </mat-card>

        <br />

        <div style="margin-bottom: 20px">
          <button mat-button (click)="openDialog(null)" style="color: #3f51b5">
            <mat-icon>add</mat-icon>
            Nova Integração
          </button>
        </div>

        <div class="mat-elevation-z8">
          <table mat-table [dataSource]="service.dataSource">
            '', 'Última Sincronização'
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef> Sistema</th>
              <td mat-cell *matCellDef="let element"> {{getEnumDescription(element.type)}} </td>
            </ng-container>

            <ng-container matColumnDef="createdAt">
              <th mat-header-cell *matHeaderCellDef> Data de criação</th>
              <td mat-cell *matCellDef="let element"> {{formatDate(element.createdAt)}}</td>
            </ng-container>

            <ng-container matColumnDef="lastSync">
              <th mat-header-cell *matHeaderCellDef> Última sincronização</th>
              <td mat-cell *matCellDef="let element"> {{formatDate(element.lastSync)}} </td>
            </ng-container>

            <ng-container matColumnDef="history">
              <th mat-header-cell *matHeaderCellDef class="actions"> Histórico</th>
              <td mat-cell *matCellDef="let element" class="actions">
                <button mat-button class="action-link" title="Histórico de sincronização" style="color: green"
                  (click)="openHistoryDialog(element)">
                  <mat-icon matBadgeSize="small" matBadgeColor="warn">
                    history
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="warnings">
              <th mat-header-cell *matHeaderCellDef class="actions"> Pendências</th>
              <td mat-cell *matCellDef="let element" class="actions">
                <button mat-button class="action-link" title="Colaboradores não sincronizados"
                  (click)="openEmployeesDialog(element)">
                  <mat-icon [matBadge]="element.employeesNotSynchronized.length"
                    [matBadgeHidden]="element.employeesNotSynchronized.length == 0"
                    [style]="element.employeesNotSynchronized.length > 0 ? 'color: orange;': 'color: #8bc34a'"
                    matBadgeSize="small" matBadgeColor="warn">
                    {{ element.employeesNotSynchronized.length == 0 ? 'assignment_turned_in' : 'warning_amber'}}
                  </mat-icon>
                </button>
              </td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="actions"> Ações</th>
              <td mat-cell *matCellDef="let element" class="actions">
                <button mat-button class="action-link" (click)="openDialog(element)" title="Editar integração"
                  [disabled]="element.isSynchronizing"
                  [style]="element.isSynchronizing ? 'color: grey' : 'color: purple;'">
                  <mat-icon matBadgeSize="small">
                    mode_edit
                  </mat-icon>
                </button>
                <button mat-button class="action-link" (click)="service.sync(element)" title="Sincronizar agora"
                  [color]="element.available ? 'primary' : null"
                  [disabled]="!element.available || element.isSynchronizing ? true : null"
                  [ngClass]="!element.available ? 'disabled' : element.isSynchronizing ? 'synchronizing' : ''">
                  <i class="fas fa-sync-alt fa-lg"></i>
                </button>
                <button mat-button class="action-link" (click)="toggleStatus(element)"
                  [title]="element.available ? 'Pausar Integração' : 'Ativar Integração'"
                  [style]="element.available ? 'color: grey' : 'color: red'" [disabled]="element.isSynchronizing">
                  <i [ngClass]=" element.available ? 'fas fa-pause fa-lg': 'fas fa-play fa-1x'"></i>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <app-empty-list *ngIf="this.service.integrations.length == 0"></app-empty-list>
          <mat-paginator
            [pageSizeOptions]="[5, 10]"
            [showFirstLastButtons]="true"
            [length]="this.service.integrations.length"></mat-paginator>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
