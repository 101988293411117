<div>
  <button class="close" mat-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="row justify-content-center">
    <h2>Histórico de Sincronizações</h2>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" (matSortChange)="announceSortChange($event)" class="mat-elevation-z0" matSort>
      <!-- Weight Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Status </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            [matTooltip]="element.status.description"
            [style]="element.status.style">
            {{ element.status.icon }}
          </mat-icon>
        </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Colaborador </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="lastSync">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Última Sincronização </th>
        <td mat-cell *matCellDef="let element">{{formatDate(element.lastSync) || '-'}}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="reasonNotToSync">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="fixed-width"> Pendências </th>
        <td mat-cell *matCellDef="let element">
          {{element.reasonNotToSync}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <app-empty-list *ngIf="dataSource.data.length == 0"></app-empty-list>
  </div>

  <div class="legend">
    <div>
      <mat-icon style="color: #4caf50">assignment_turned_in</mat-icon>
      Sincronizado
    </div>
    <div>
      <mat-icon style="color: orange">assignment_late</mat-icon>
      Pendente
    </div>
    <div>
      <mat-icon style="color: blue">pending_actions</mat-icon>
      Aguardando
    </div>
    <div>
      <mat-icon style="color: red">close</mat-icon>
      Não Sincronizado
    </div>
  </div>
</div>
