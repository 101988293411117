import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ListComponent} from './pages/integration/list/list.component';
import {CreateComponent} from './pages/integration/create/create.component';

import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatStepperModule} from '@angular/material/stepper';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatInputModule} from '@angular/material/input';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {InstructionsSolidesComponent} from './components/instructions-solides/instructions-solides.component';
import {InstructionsConveniaComponent} from './components/instructions-convenia/instructions-convenia.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatBadgeModule} from '@angular/material/badge';
import { EmployeesComponent } from './pages/integration/employees/employees.component';
import { HistoryComponent } from './pages/integration/history/history.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSortModule} from '@angular/material/sort';
import {ErrorsModule} from "./pages/errors/errors.module";

@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    CreateComponent,
    ConfirmDialogComponent,
    InstructionsSolidesComponent,
    InstructionsConveniaComponent,
    EmployeesComponent,
    HistoryComponent,
    EmptyListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatCardModule,
    MatStepperModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatGridListModule,
    MatButtonToggleModule,
    MatInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTabsModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTooltipModule,
    MatSortModule,
    ErrorsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
