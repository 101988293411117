import {Injectable, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import IntegrationModel from '../models/integration.model';
import {MatTableDataSource} from '@angular/material/table';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatPaginator} from '@angular/material/paginator';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
  }

  employer = '';
  employerId = '';
  integrations = [];
  dataSource = new MatTableDataSource();
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  save(entity: IntegrationModel) {
    return this.http.post(`${environment.API_BASE_PATH}/integration/store`, entity, {
      headers: {
        employer: this.employer,
        "employer-id": this.employerId
      }
    }).toPromise();
  }

  async findAll() {
    let result = [];
    await this.http.get(`${environment.API_BASE_PATH}/integration/find`, {
      headers: {
        employer: this.employer
      }
    }).toPromise().then((item: any) => {
      if (item && item.body) {
        this.integrations = [];

        item.body.forEach((element: any) => {
            this.integrations.push(
              new IntegrationModel()
                .builder()
                .setId(element._id)
                .setType(element.type)
                .setOrigin(element.origin)
                .setHeaders(element.headers)
                .setLastSync(element.lastSync)
                .setCreatedAt(element.createdAt)
                .setAvailable(element.available)
                .setVacation(element.module.vacation)
                .setManager(element.module.manager)
                .setEmployeesNotSynchronized(element.employeesNotSynchronized)
            );
          }
        );
        this.updateDataSource();
        result = this.integrations;
      }
    });

    return result;
  }

  validate(integration) {
    delete integration.employeesNotSynchronized

    return this.http.post(`${environment.API_BASE_PATH}/integration/${integration.type}/validate`, integration, {
      headers: {
        employer: this.employer
      }
    }).toPromise();
  }

  validateHeaders(integration) {
    return this.http.post(`${environment.API_BASE_PATH}/integration/${integration.type}/find`, integration, {
      headers: {
        employer: this.employer
      }
    }).toPromise();
  }

  async sync(entity) {
    entity.isSynchronizing = true;

    await this.http.get(`${environment.API_BASE_PATH}/integration/${entity.id}/sync`, {
      headers: {
        employer: this.employer
      }
    }).toPromise()
      .then(async (response: any) => {
        if (response && response.success) {
          await this.findAll();

          this.showFeedBack('Sincronizado com sucesso!');
          let integration = _.find(this.integrations, (item) => item.id === entity.id);
          integration.lastSync = new Date();

          return;
        }
        this.showFeedBack('Não foi possível sincronizar.');
      })
      .catch(error => this.showFeedBack('Não foi possível sincronizar.'))
      .finally(() => entity.isSynchronizing = false);
  }

  toggleStatus(id) {
    return this.http.put(`${environment.API_BASE_PATH}/integration/${id}/toggle-status`, {}, {
      headers: {
        employer: this.employer
      }
    }).toPromise()
      .then((response: any) => {
          if (response && response.success) {
            let integration = _.find(this.integrations, (item) => item.id === id);

            integration.available = !integration.available;
            this.updateDataSource();

            this.snackBar.open('Integração atualizada com sucesso!', 'Fechar', {
              duration: 3000
            });
          }
        }
      )
      .catch(error => this.showFeedBack('Não foi possível atualizar esta integração.'));
  }

  showFeedBack(message) {
    this.snackBar.open(message, 'Fechar', {
      duration: 3000
    });
  }

  updateDataSource() {
    this.dataSource.data =  _.orderBy(this.integrations, ['createdAt'], ['desc']);
    this.dataSource.paginator = this.paginator;
  }
}
