<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    <!-- Weight Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Colaborador </th>
      <td mat-cell *matCellDef="let element" class="fixed-width"> {{element.name}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="inconsistency">
      <th mat-header-cell *matHeaderCellDef> Pendências </th>
      <td mat-cell *matCellDef="let element">{{element.reasonNotToSync}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <ng-container *ngComponentOutlet="emptyList"></ng-container>
</div>


<ng-template #emptyList>
  <div class="container" *ngIf="dataSource.length == 0">
    <mat-icon>inbox</mat-icon>
    <p>
      Não há nada para exibir
    </p>
  </div>
</ng-template>
