export enum IntegrationTypeEnum {
  SOLIDES = 'SOLIDES',
  CONVENIA = 'CONVENIA',
  TANGERINO = 'TANGERINO'
}

export const getDescription = (value) => {
  switch (value) {
    case IntegrationTypeEnum.CONVENIA:
      return 'Convenia';
    case IntegrationTypeEnum.SOLIDES:
      return 'Sólides';
    case IntegrationTypeEnum.TANGERINO:
      return 'Tangerino';
    default:
      return '';
  }
};
