import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IntegrationService } from './integration.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(private http: HttpClient, private service: IntegrationService) { }

  findAll(id) {
    return this.http.get(`${environment.API_BASE_PATH}/integration/${id}/employee/find`, {
      headers: { employer: this.service.employer }
    }).toPromise()
  }
}
