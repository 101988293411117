import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateComponent } from '../create/create.component';
import { MatDialog } from '@angular/material/dialog';
import { IntegrationService } from 'src/app/services/integration.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import {EmployeesComponent} from "../employees/employees.component";
import {getDescription} from "../../../models/enums/integration.type.enum";
import { HistoryComponent } from '../history/history.component';
import DateUtils from 'src/app/utils/DateUtils';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  @ViewChild(MatPaginator, {static : false}) paginator!: MatPaginator;
  displayedColumns: string[] = ['type', 'createdAt', 'lastSync', 'history', 'warnings', 'actions'];
  token = "Basic " + this.service.employer;

  constructor(
    public dialog: MatDialog,
    public service: IntegrationService
  ) {
    this.findAll();
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'itens por página';
    this.paginator._intl.getRangeLabel =this.getRangeDisplayText
  }

  async findAll() {
    await this.service.findAll();
  }

  getRangeDisplayText = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length 
      ? Math.min(startIndex + pageSize, length) 
      : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`; // customize this line
  };

  openDialog(entity) {
    this.dialog.open(
      CreateComponent, {
      width: '700px',
      height: '485px',
      panelClass: 'custom-dialog',
      data: { entity }
    });
  }

  openEmployeesDialog(entity) {
    this.dialog.open(
      EmployeesComponent, {
      width: '700px',
      height: '485px',
      panelClass: 'custom-dialog',
      data: { entity }
    });
  }

  openHistoryDialog(entity) {
    this.dialog.open(
      HistoryComponent, {
        width: '850px',
        height: '500px',
        panelClass: 'custom-dialog',
        data: { entity }
      });
  }

  formatDate(date: Date) {
    return DateUtils.formatDate(date)
  }

  async remove(id) {
    const message = 'Você deseja realmente excluir a integração?';
    const dialogData = new ConfirmDialogModel("Confirmação", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.service.toggleStatus(id);
      }
    });
  }

  openToken() {
    const element = document.getElementById('Token');

    if (element.className === 'card-msg') {
      element.classList.add('actived');
    } else {
      element.classList.remove('actived');
    }
  }

  toggleStatus(integration) {
    return this.service.toggleStatus(integration.id);
  }

  getEnumDescription(enumerate) {
    return getDescription(enumerate);
  }
}
