<button class="close" mat-button (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<div class="row justify-content-center">
  <h2>Colaboradores não sincronizados</h2>
</div>
<div class="table-container">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
    <!-- Weight Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Colaborador </th>
      <td mat-cell *matCellDef="let element" class="fixed-width"> {{element.name}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="inconsistency">
      <th mat-header-cell *matHeaderCellDef> Pendências </th>
      <td mat-cell *matCellDef="let element">{{element.reasonNotToSync}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <app-empty-list *ngIf="dataSource.length == 0"></app-empty-list>
</div>
