import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import IntegrationModel from "../../../models/integration.model";
import {IntegrationService} from "../../../services/integration.service";

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'inconsistency'];
  dataSource = [];

  constructor(public service: IntegrationService) {
    this.findAll();
  }

  ngOnInit(): void {  }


  async findAll() {
    const result = await this.service.findAll();

    if (!result || !result[0]) return;

    this.dataSource = result[0].employeesNotSynchronized;
  }


}
