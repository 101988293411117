import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ErrorsRoutingModule} from './errors-routing.module';
import {ErrorsComponent} from "./components/errors.component";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [ErrorsComponent],
  imports: [
    CommonModule,
    ErrorsRoutingModule,
    MatTableModule,
    MatIconModule
  ]
})
export class ErrorsModule {
}
